export const bffApiUrl = '/api'

export const bffProxyUrl = `${bffApiUrl}/proxy`

export const staticUrl = `${bffApiUrl}/static`

/**
 * Returns the base URL for the static site.
 * @returns The base URL for the static site.
 */
export const staticSiteBaseUrl = (): string => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'dev') {
    return 'dev.charterup-qa.com'
  }
  return 'www.charterup.com'
}

/**
 * Returns the base URL for the operators site.
 * @returns The base URL for the operators site.
 */
export const operatorsBaseUrl = (): string => {
  const hostSubdomain = window.location.host.split(':')[0]
  if (hostSubdomain === 'localhost') {
    return 'dev.operators.charterup.com'
  }
  return hostSubdomain
}

export const splitKey = (): string => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return 'q65ir5g1n7pek8dn6f7rm4n0ua6patbsv69p'
  } else if (hostSubdomain === 'auto') {
    return 'q17bli4sobmq2riile3p8vm6vjdg9rkkcgr4'
  } else if (hostSubdomain === 'operators') {
    return '4mfo5hbqg0cbkifhoocbtj26jukglmlbf1o9'
  }
}

/**
 * Provides the Finix config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const finixConfig = (): { environment: 'sandbox' | 'live', applicationId: string } => {

  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]

  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'auto') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'operators') {
    return {
      environment: "live",
      applicationId: "ADD PRODUCTION FINIX APPLICATION ID HERE"
    }
  }

  return null
}
