import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import { SplitFactory } from '@splitsoftware/splitio'
import {
  SplitTrafficType,
  SplitKeyType,
  SplitFeatureFlag,
  SplitAttribute,
} from '@/utils/enum'
import store from '@/store/index'
import { splitKey } from '@/utils/env'

@Module({ generateMutationSetters: true })
class SplitModule extends VuexModule {
  _splitClient: SplitIO.IClient = null
  _key: string = SplitKeyType.AnonymousCompany
  _splitFactory: SplitIO.ISDK = SplitFactory({
    core: {
      authorizationKey: splitKey(),
      trafficType: SplitTrafficType.Company,
      key: this.key,
    },
    startup: {
      readyTimeout: 1.5,
    },
  })
  _isReady: boolean = false
  _attributes: SplitIO.Attributes = {
    [SplitAttribute.OperatorMarket]: null,
  }

  /**
   * Split client used to access feature flag values and change attributes.
   * @returns the Split client.
   */
  get client(): SplitIO.IClient {
    return this._splitClient
  }

  /**
   * Split key used to differentiate betweeen companies.
   * @returns the company key.
   */
  get key(): string {
    return this._key
  }

  /**
   * Is the Split client ready to be used.
   * @returns the Split client status.
   */
  get isReady(): boolean {
    return this._isReady
  }

  /**
   * Updates the attributes associated with the company.
   * @param attributes - the attribute map for a given company.
   */
  @Mutation
  setClientAttributes(attributes: SplitIO.Attributes): void {
    this._attributes = attributes
  }

  /**
   * Check whether a feature flag is enabled.
   * @param flag - the feature flag to check.
   * @returns true if the feature flag is enabled, false otherwise.
   */
  @Action
  async isFeatureEnabled(flag: SplitFeatureFlag): Promise<boolean> {
    if (!this.isReady || !flag) {
      return false
    }
    const treatment =
      (await this.client.getTreatment(flag, this._attributes)) === 'on'
    return treatment
  }

  /**
   * Updates the current key and reinitializes client if it's new.
   * @param key - the key to update.
   * @returns void
   */
  @Action
  async updateKey(
    key: SplitKeyType | string = SplitKeyType.AnonymousCompany
  ): Promise<void> {
    const shouldReinitialize = this._key !== key
    this._key = key
    if (shouldReinitialize) {
      await this.initializeClient()
    }
  }

  /**
   * Create a new client and reinitializes if existing.
   * @returns void
   */
  @Action
  async initializeClient(): Promise<void> {
    if (this.client !== null) {
      await this.client.destroy()
      this._isReady = false
      // destroying the client also destroys the factory
      this._splitFactory = SplitFactory({
        core: {
          authorizationKey: splitKey(),
          trafficType: SplitTrafficType.Company,
          key: this.key,
        },
        startup: {
          readyTimeout: 1.5,
        },
      })
    }

    try {
      const trafficType =
        this._key === SplitKeyType.AnonymousCompany
          ? SplitTrafficType.Anonymous
          : SplitTrafficType.Company
      const client = this._splitFactory.client(this.key, trafficType)
      await client.ready().catch((e) => {
        throw e
      })
      this._splitClient = client
      this._isReady = true
    } catch (e) {
      console.warn(e)
    }
  }
}

export default new SplitModule({ store, name: 'split' })
