import { FeatureToggle } from '@/models/dto/FeatureToggle'
import featureToggle from '@/services/featureToggle'
import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '..'

@Module({ generateMutationSetters: true })
class FeatureToggleModule extends VuexModule {
  _featureToggles: FeatureToggle[] = []

  /**
   * Returns the feature toggles.
   *
   * @returns The feature toggles as an array of `FeatureToggle` objects.
   */
  get featureToggles(): FeatureToggle[] {
    return this._featureToggles
  }

  /**
   * Asynchronously fetch feature toggles.
   */
  @Action
  async fetchFeatureToggles(): Promise<void> {
    const response = await featureToggle.getFeatureToggles()
    this._featureToggles = response.data.featureToggles
  }

  // PRIVATE HELPERS
  checkFeatureToggle = (
    featureToggles: FeatureToggle[],
    featureToggleName: string
  ): boolean | null => {
    const foundToggle = this.findFeatureToggle(
      featureToggles,
      featureToggleName
    )
    return foundToggle?.enabled
  }

  findFeatureToggle = (
    featureToggles: FeatureToggle[],
    featureToggleName: string
  ): FeatureToggle | null => {
    return featureToggles.find(
      (featureToggle) => featureToggle.name === featureToggleName
    )
  }
}

export default new FeatureToggleModule({ store, name: 'featureToggle' })
